import { addCssStyle } from '@/utils/addCssStyle';
import { isExpiredJWT } from '@/utils/DecodeJWT';
import { setLocationIdForPasswordResetUrl } from './sharedFunctions';

export const ACTION_FETCH_SITE_INFO = 'siteInfo/fetchSiteInfo';
export const ACTION_UPDATE_USER_SHARE_VLAN = 'siteInfo/updateUserShareVlan';
export const MUTATION_UPSERT_LOGGED_IN = 'siteInfo/upsertLoggedIn';

function convertBinToBoolean(value) {
  if (value === '1') {
    return true;
  }

  if (value === '0') {
    return false;
  }

  return null;
}

const siteInfoErrorWaitTime = 20000;

export const siteInfo = {
  namespaced: true,
  state: {
    enable3DS: false,
    location: null,
    locationCode: null,
    locationID: null,
    customerCode: null,
    locationDisplayOptions: null,
    isLoggedIn: null,
    lastLoginSite: null,
    user: null,
    stripeAccountID: null,
    loginError: null,
    hasActiveSubscription: false,
    fbEnabled: false,
    defaultPaymentMethod: null,
    zoneData: null,
    termsAndConditions: {
      accepted: null,
      text: null,
    },
    userID: null,
    accessCode: null,
    billingInformation: {
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      address: null,
      postcode: null,
      mobile: null,
      dob: null,
    },
    mac: null,
    activeDevices: null,
    addDeviceIndex: null,
    externalIdLookupEnabled: false,
    hasActedOnMarketing: null,
    landingText: null,
    emailActivation: false,
    detailedAgreements: false,
    isImpersonating: false,
    companyName: null,
    support: {
      link: null,
      text: null,
      phone: null,
      email: null,
    },
    wiredNetworkingEnabled: false,
    header: null,
    footer: null,
    logo: null,
    background: null,
    visibleTabs: [],
    visibleDashboardBlocks: [],
    allowedEmailDomains: '',
    buttonChatStyle: null,
    isNoUserJourneyEnabled: false,
    isWifinityOneEnabled: false,
    portalType: '',
    noUserJourneyLoggedIn: false,
    isEnabledNujHeadlessDevices: false,
    showFeedback: true,
    showSpeedTest: true,
    isCpeEnabled: false,
    isBlackListed: false,
    siteCssInfoResponse: {},
    subheader: {},
    headlessDevice: false,
    homeNetworkingEnabled: false,
    usingExternalDpsk: false,
    enabled: true,
    notification: {
      id: '',
      endDate: '',
      importance: '',
      message: '',
      startDate: '',
      title: '',
    },
    mfaViaSms: false,
    messagingAndBrowsing: false,
    isOutsideNetwork: false,
    hnCloudpathDeviceAddEnabled: false,
    siteInfoErrorTime: null,
    hasEnteredRouter: false,
    planEngine: '',
    shareVlan: false,
  },
  getters: {
    steps(state) {
      return state.isLoggedIn ? 2 : 3;
    },
    isSiteEnabled(state) {
      return state.enabled;
    },
    isEmailActivationEnabled(state) {
      return state.emailActivation;
    },
    isSFA(state) {
      return state.portalType && state.portalType.toLowerCase() === 'sfa';
    },
    isWifinityOneEnabled(state) {
      return state.isWifinityOneEnabled;
    },
    isPAYG(state) {
      return state.portalType && state.portalType.toLowerCase() === 'payg';
    },
    isAnchor(state) {
      return state.portalType && state.portalType.toLowerCase() === 'anchor';
    },
    isAnchorNUJ(state) {
      return state.portalType && state.portalType.toLowerCase() === 'anchor_nuj';
    },
    isFreeSignUp(state) {
      return state.portalType && state.portalType.toLowerCase() === 'free_sign_up';
    },
    isNAAS(state) {
      return state.portalType && state.portalType.toLowerCase() === 'naas';
    },
    isFujitsu(state) {
      return state.portalType
        && state.portalType.toLowerCase() === 'free_sign_up'
        && state.customerCode
        && state.customerCode.toLowerCase() === 'fj010';
    },
    isWoodvale(state) {
      return state.customerCode
      && state.customerCode.toLowerCase() === 'WIF010MODNAASWDVL'.toLowerCase();
    },
  },
  mutations: {
    upsertHasEnteredRouter(state, hasEnteredRouter) {
      state.hasEnteredRouter = hasEnteredRouter;
    },
    upsertEnable3DS(state, enable3DS) {
      state.enable3DS = enable3DS;
    },
    upsertMac(state, mac) {
      state.mac = mac;
    },
    upsertAddDeviceIndex(state, addDeviceIndex) {
      state.addDeviceIndex = addDeviceIndex;
    },
    upsertLoginError(state, errorStatus) {
      state.loginError = errorStatus;
    },
    upsertLocation(state, locations) {
      state.location = locations;
    },
    upsertLocationCode(state, locationCode) {
      state.locationCode = locationCode;
    },
    upsertLocationID(state, locationID) {
      state.locationID = locationID;
    },
    upsertCustomerCode(state, customerCode) {
      state.customerCode = customerCode;
    },
    upsertLocationDisplayOptions(state, displayOptions) {
      state.locationDisplayOptions = displayOptions;
    },
    upsertLoggedIn(state, status) {
      state.isLoggedIn = status;
    },
    upsertStripeAccountID(state, id) {
      state.stripeAccountID = id;
    },
    upsertUser(state, user) {
      state.user = user;
    },
    upsertHasActiveSubscription(state, hasActiveSubscription) {
      state.hasActiveSubscription = hasActiveSubscription;
    },
    upsertUserID(state, id) {
      state.userID = id;
    },
    upsertUserLastInvoice(state, lastInvoice) {
      state.user.lastInvoice = lastInvoice;
    },
    upsertZoneData(state, data) {
      state.zoneData = data;
    },
    upsertAccessCode(state, data) {
      state.accessCode = data;
    },
    upsertBillingInformation(state, data) {
      state.billingInformation = { ...data };
    },
    upsertFbEnabled(state, fbEnabled) {
      state.fbEnabled = fbEnabled;
    },
    upsertDefaultPaymentMethod(state, method) {
      state.defaultPaymentMethod = method;
    },
    upsertActiveDevices(state, data) {
      state.activeDevices = data;
    },
    upsertTermsAndConditions(state, data) {
      state.termsAndConditions = data;
    },
    upsertEnteredPortal(state, data) {
      state.enteredPortal = data;
    },
    upsertExternalIdLookupEnabled(state, data) {
      state.externalIdLookupEnabled = data || false;
    },
    upsertHasActedOnMarketing(state, data) {
      state.hasActedOnMarketing = data;
    },
    upsertLandingText(state, data) {
      state.landingText = data;
    },
    upsertEmailActivation(state, data) {
      state.emailActivation = data;
    },
    upsertDetailedAgreements(state, data) {
      state.detailedAgreements = data;
    },
    upsertHomeNetworkingEnabled(state, data) {
      state.homeNetworkingEnabled = data;
    },
    upsertUsingExternalDpsk(state, data) {
      state.usingExternalDpsk = data;
    },
    upsertImpersonation(state, data) {
      state.isImpersonating = data;
    },
    upsertSupportData(state, data) {
      if (!data) return;
      state.support.link = data.link;
      state.support.text = data.text;
      if (data.phone) {
        state.support.phone = data.phone;
      }
      if (data.email) {
        state.support.email = data.email;
      }
    },
    upsertWiredNetworkingEnabled(state, data) {
      state.wiredNetworkingEnabled = data;
    },
    upsertHeader(state, data) {
      state.header = data;
    },
    upsertFooter(state, data) {
      state.footer = data;
    },
    upsertLogo(state, data) {
      state.logo = data;
    },
    upsertBackground(state, data) {
      state.background = data;
    },
    upsertVisibleTabs(state, data) {
      state.visibleTabs = data;
    },
    upsertVisibleDashboardBlocks(state, data) {
      state.visibleDashboardBlocks = data;
    },
    upsertAllowedEmailDomains(state, data) {
      state.allowedEmailDomains = data;
    },
    upsertButtonChatStyle(state, data) {
      state.buttonChatStyle = data;
    },
    upsertIsNoUserJourneyEnabled(state, data) {
      state.isNoUserJourneyEnabled = data;
    },
    upsertIsWifinityOneEnabled(state, data) {
      state.isWifinityOneEnabled = data || false;
    },
    upsertPortalType(state, data) {
      state.portalType = data;
    },
    upsertIsEnabledNujHeadlessDevices(state, data) {
      state.isEnabledNujHeadlessDevices = data;
    },
    upsertNoUserJourneyLoggedIn(state, data) {
      state.noUserJourneyLoggedIn = data;
    },
    upsertHeadlessDevice(state, data) {
      state.headlessDevice = data;
    },
    upsertShowFeedback(state, data) {
      state.showFeedback = data;
    },
    upsertShowSpeedTest(state, data) {
      state.showSpeedTest = data;
    },
    upsertIsCpeEnabled(state, data) {
      state.isCpeEnabled = data;
    },
    upsertIsBlackListedMac(state, data) {
      state.isBlackListed = data;
    },
    upsertSiteCssInfoResponse(state, data) {
      state.siteCssInfoResponse = data;
    },
    upsertSubheader(state, data) {
      state.subheader = data;
    },
    upsertEnabled(state, data) {
      state.enabled = data;
    },
    upsertNotification(state, data) {
      state.notification = data;
    },
    upsertMfaViaSms(state, data) {
      state.mfaViaSms = data;
    },
    upsertMessagingAndBrowsing(state, data) {
      state.messagingAndBrowsing = data;
    },
    upsertHnCloudpathDeviceAddEnabled(state, data) {
      state.hnCloudpathDeviceAddEnabled = data;
    },
    upsertPlanEngine(state, data) {
      state.planEngine = data;
    },
    siteInfoErrorTime(state, data) {
      state.siteInfoErrorTime = data;
    },
    upsertShareVlan(state, data) {
      state.shareVlan = data;
    },
    upsertUserShareVlan(state, data) {
      state.user.shareVlan = data;
    },
  },
  actions: {
    fetchBillforwardContext({ commit, rootState }) {
      return rootState.api.stardust.get('/context/billforward')
        .then((response) => {
          commit('api/upsertBillforwardApi', response.data, { root: true });

          return response.data;
        });
    },
    async fetchSiteInfo({ commit, dispatch, rootState }, routes) {
      if (!await dispatch('canCallSiteInfo', routes)) {
        console.log('Site info call failed earlier, try again later');
        return;
      }
      setLocationIdForPasswordResetUrl('', location.href);
      return dispatch('global/fetchPublicConfig', null, { root: true })
        .then(() => {
          if (isExpiredJWT()) {
            return;
          }
          return rootState.api.stardust.get('/site-info')
            .catch((error) => {
              if (error && error.response && error.response.status && error.response.status === 404) {
                if (routes && routes.next) {
                  routes.next({ name: 'portal-not-found' });
                } else if (this.$router) {
                  this.$router.push({
                    name: 'portal-not-found',
                  });
                }
              }
              return Promise.reject(error);
            })
            .then(async (response) => {
              commit(
                'siteInfoErrorTime',
                null,
              );
              if (response.data.user) {
                commit('upsertStripeAccountID', response.data.user.stripeUserInfo.customerId);
                commit('upsertUserID', response.data.user.id);
                commit('upsertUser', {
                  ...response.data.user,
                  username: response.data.user.firstName,
                  marketingOptIn: !!response.data.user.marketingOptIn,
                  detailedMarketingOptIn: response.data.user.detailedMarketingOptIn
                    && Object.keys(response.data.user.detailedMarketingOptIn)
                      .reduce((result, key) => {
                        result[key] = convertBinToBoolean(
                          response.data.user.detailedMarketingOptIn[key],
                        );
                        return result;
                      }, {}),
                });
                commit('upsertHasActiveSubscription', response.data.user.hasActiveSubscription);
                commit('upsertHasActedOnMarketing', response.data.user.marketingOptIn);
              } else {
                commit('upsertStripeAccountID', null);
                commit('upsertUser', null);
                commit('upsertHasActiveSubscription', false);
              }

              if (response.data.loginError) {
                commit('upsertLoginError', response.data.loginError);
              }

              if (response.data.thisSite && response.data.thisSite.features) {
                commit('upsertEmailActivation', response.data.thisSite.features.email_activation);
                commit('upsertDetailedAgreements', response.data.thisSite.features.detailed_agreements);
                commit('upsertHomeNetworkingEnabled', response.data.thisSite.features.home_networking);
              }

              commit('upsertFbEnabled', response.data.fbEnabled);
              commit('upsertEnable3DS', response.data['3ds_enabled']);
              commit('upsertLocation', response.data.thisSite.name);
              commit('upsertLocationDisplayOptions', response.data.thisSite.displayOptions);
              commit('upsertLocationCode', response.data.thisSite.code);
              commit('upsertLocationID', response.data.thisSite.locationID);
              commit('upsertCustomerCode', response.data.thisSite.customerCode);
              commit('upsertLoggedIn', response.data.isLoggedIn);
              commit('upsertMac', response.data.mac);
              commit('upsertExternalIdLookupEnabled', response.data.externalIdLookupEnabled);
              commit('upsertLandingText', response.data.landingText);
              commit('upsertImpersonation', response.data.isImpersonating);
              commit('upsertSupportData', response.data.support);
              commit('upsertWiredNetworkingEnabled', response.data.wiredNetworkingEnabled);
              commit('upsertUsingExternalDpsk', response.data.usingExternalDpsk);
              commit('upsertHeader', response.data.header);
              commit('upsertFooter', response.data.footer);
              commit('upsertLogo', response.data.logo);
              commit('upsertBackground', response.data.background);
              commit('upsertVisibleTabs', response.data.visibleTabs);
              commit('upsertVisibleDashboardBlocks', response.data.visibleDashboardBlocks);
              commit('upsertAllowedEmailDomains', response.data.allowedEmailDomains);
              commit('upsertButtonChatStyle', response.data.buttonChatStyle);
              commit('upsertIsNoUserJourneyEnabled', response.data.isNoUserJourneyEnabled);
              commit('upsertIsWifinityOneEnabled', response.data.isWifinityOneEnabled);
              commit('upsertPortalType', response.data.portalType);
              commit('upsertIsEnabledNujHeadlessDevices', response.data.isEnabledNujHeadlessDevices);
              commit('upsertShowFeedback', response.data.showFeedback);
              commit('upsertShowSpeedTest', response.data.showSpeedTest);
              commit('upsertIsCpeEnabled', response.data.isCpeEnabled);

              commit('upsertSubheader', response.data.subheader);
              commit('upsertEnabled', response.data.enabled);
              commit('upsertNotification', response.data.notification);
              commit('upsertMfaViaSms', response.data.mfaViaSms);
              commit('upsertPlanEngine', response.data.planEngine);
              commit('upsertMessagingAndBrowsing', response.data.messagingAndBrowsing);
              commit('upsertHnCloudpathDeviceAddEnabled', response.data.hnCloudpathDeviceAddEnabled);
              commit('upsertShareVlan', Boolean(response.data.shareVlan));

              const portalTypes = ['sfa', 'anchor', 'anchor_nuj'];
              for (const portal of portalTypes) {
                if (response.data.portalType && response.data.portalType.toLowerCase() === portal) {
                  response.data.siteCssInfoResponse.name = portal;
                }
              }

              if (response.data.portalType
                && response.data.portalType.toLowerCase() === 'free_sign_up'
                && response.data.thisSite
                && response.data.thisSite.customerCode
                && response.data.thisSite.customerCode.toLowerCase() === 'fj010') {
                response.data.siteCssInfoResponse.name = 'fujitsu';
              }

              commit('upsertSiteCssInfoResponse', response.data.siteCssInfoResponse);
              addCssStyle(response.data.siteCssInfoResponse);

              return Promise.resolve();
            }, (err) => {
              console.warn('Something went wrong at ', err);
              if (err && err.response && err.response.status
                && (err.response.status === 404 || err.response.status === 400)) {
                commit(
                  'siteInfoErrorTime',
                  new Date(),
                );
                if (routes.next) {
                  routes.next({ name: 'portal-not-found' });
                } else if (this.$router) {
                  this.$router.push({
                    name: 'portal-not-found',
                  });
                }
              } else {
                commit(
                  'siteInfoErrorTime',
                  new Date(),
                );
                commit(
                  'global/replaceError',
                  (err && err.response && err.response.data && err.response.data.error) || err,
                  { root: true },
                );
                throw err;
              }
            });
        });
    },
    isSubscriptionActive({ rootState }) {
      return rootState.api.stardust.get('/site-info/active-subscription')
        .then(response => response.data);
    },
    fetchDefaultPaymentMethod({ commit, state, rootState }) {
      if (state.planEngine === 'INTERNAL') return;
      return rootState.api.stardust.get('/payment-method').then((response) => {
        commit('upsertDefaultPaymentMethod', response.data || null);
      });
    },
    hasZoneData({ state }) {
      return !!state.zoneData;
    },
    getAccessCodeData({ commit, rootState }, activationCode) {
      return rootState.api.stardust.post(`/access-code/validate/${activationCode}`).then((response) => {
        commit('upsertAccessCode', response.data);
        commit('upsertZoneData', response.data.accessPeriod.zone);

        return response.data;
      });
    },
    updateUserShareVlan({ commit, rootState }) {
      return rootState.api.stardust.post('/share-vlan')
        .then((response) => {
          commit('upsertUserShareVlan', Boolean(response.data.shareVlan));
        });
    },
    fetchAccessCodeData({ commit, rootState }, activationCode) {
      commit('upsertZoneData', null);
      return rootState.api.stardust.get(`/access-code/${activationCode}`)
        .then((response) => {
          commit('upsertAccessCode', response.data);
          commit('upsertZoneData', response.data.accessPeriod.zone);
        });
    },
    clearAccessCodeData({ commit }) {
      commit('upsertZoneData', null);
    },
    getTermsAndConditions({ commit, rootState }) {
      return rootState.api.stardust.get('/terms')
        .then((response) => {
          commit('upsertTermsAndConditions', response.data || null);
        },
        (err) => {
          throw err;
        });
    },
    fetchNoUserJourneyLoggedIn({ commit, rootState }, { headlessMac, isTermsAndConditionAccepted }) {
      return rootState.api.stardust.post('/user/no-account-user',
        { headlessMac, isTermsAndConditionAccepted })
        .then(() => {
          commit('upsertNoUserJourneyLoggedIn', isTermsAndConditionAccepted);
          commit('upsertHeadlessDevice', !!headlessMac);
        });
    },
    fetchMacIsBlackListed({ commit, rootState }, data) {
      return rootState.api.stardust.get(`/cpe/check/${data.customerCode}/${data.locationCode}/${data.mac}`)
        .then((response) => {
          commit('upsertIsBlackListedMac', response.data.isBlackListed);
        });
    },
    canCallSiteInfo({ state }, routes) {
      const routerNotInitialized = !this.$route;
      const routesPassed = routes && routes.to;
      if (routerNotInitialized && (routesPassed && (routes.to.name === 'portal-not-found' || routes.to.name === 'error'))) {
        console.log('Portal not found');
        return;
      }
      if (this.$route && this.$route.name === 'portal-not-found') {
        console.log('Portal not found');
        return;
      }
      if (state.siteInfoErrorTime === null) {
        return true;
      }
      return new Date().getTime() > state.siteInfoErrorTime.getTime() + siteInfoErrorWaitTime;
    },
  },
};
